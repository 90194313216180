@import './assets/styles/fonts.scss';
@import './assets/styles/font-styles.scss';

//GENERIC

html, body {
    margin: 0;
    scroll-behavior: smooth;
    font-family: var(--font-oswald-regular);
    background-color: var(--color-grey-main);
}

html {
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

app-root {
    display: block;
    width: 100%;
    height: 100%;
}

// ########################
// GLOBAL VARS
// ########################

:root {
    --grid-gap: 24px;
    --grid-padding: 24px 80px;
    --cage-max-width: 1440px;
    --cage: 100%;
    --base-layout: 3px;
    --border-radius-size: 8px;
    --padding: 3px;
    --margin: 3px;
    --margin10: 10px;
    --margin25: 25px;
    --margin50: 50px;
    --margin80: 80px;
    --margin100: 100px;

    // component specifics
    --input-height: 35px;



    // palette
    --color-black: #000;
    --color-white: #fff;
    --color-grey-main:#141414;
    --color-grey-medium:#232323;
    --color-grey-gradient:linear-gradient(0deg , var(--color-grey-main), transparent);
    --color-deep-lilac:#8F3B89;
    --color-light-lilac:#AE68A9;
    --color-gradient:linear-gradient(0deg , var(--color-grey-main), #3A2339);
    --disabled-white: #ffffff7d;
    --semi-black: #0000007d;

    // media q rules
    --sm-cage: 1024px;
    --sm-grid-padding: 24px 40px;
    --sm-grid-gap: 20px;
    --xsm-cage: 375px;
    --xsm-grid-padding: 24px 20px;
    --xsm-grid-gap: 10px;
}

// media queries
$max-grid-columns: 12;
$xsm-max-grid-columns: 6;
$sm: 1199px;
$xsm: 991px;

// ########################
// GLOBAL RULES
// ########################

.block {
    z-index: 10;
    display: flex;
    align-items: center;

    &.black {
        background: var(--color-grey-main);
        color:var(--color-white);
        padding: var(--margin50) 0;
    }

    &.white {
        background: var(--color-white);
        color:var(--color-black);
        padding: var(--margin50) 0;
    }

    &.lilac_deep {
        background: var(--color-deep-lilac);
        color:var(--color-white);
        padding: var(--margin50) 0;
    }
}

a[href] {
    color: unset;
    text-decoration: unset;
}

.cage {
    padding: var(--grid-padding);
    display: grid;
    grid-template-columns: repeat(#{$max-grid-columns}, 1fr);
    grid-gap: var(--grid-gap);
    margin: 0 auto;
    max-width: var(--cage-max-width);
    width: var(--cage);
    box-sizing: border-box;
}

.extend-content {
    display: flex;
    flex-direction: column;
    gap: 9px;

    .extended-row {
        display: flex;
        flex: 1;
        gap: 9px;

        input, textarea {
            flex: 1;
        }
    }
}

h1, h2, h3, h4, h5 {
    margin-top: 0;
    margin-bottom: calc(var(--margin) * 7);
    font-weight: normal;
}

.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-center-h {
    display: flex;
    justify-content: center;
}

.flex-center-v {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursor {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

// MEDIA QUERIES

@media screen and (max-width: #{$sm}) {
    .cage {
        max-width: var(--sm-cage);
        padding: var(--sm-grid-padding);
        grid-gap: var(--sm-grid-gap);
    }
}

@media screen and (max-width: #{$xsm}) {
    .cage {
        max-width: var(--xsm-cage);
        padding: var(--xsm-grid-padding);
        grid-gap: var(--xsm-grid-gap);
        grid-template-columns: repeat(12, 1fr);
        grid-template-columns: repeat(#{$xsm-max-grid-columns}, 1fr);
    }
}

// CSS LOGIC

@for $i from 1 through $max-grid-columns { //.cage-n where n is the amount of columns to draw (you're going to use .cage-n inside a .cage element)
    .cage-#{$i} {
        @extend .cage;
        grid-template-columns: repeat(#{$i}, 1fr);
        padding: 0;
        flex-wrap: wrap;
    }
}

@for $i from 1 through $max-grid-columns { //.ct-n where n represents the columns to occupy in a grid
    .ct-#{$i} {
        grid-column: span #{$i};
    }
}
    
@for $startPos from 1 through $max-grid-columns { //.ct-start-x-y where x represents at what column to start drawing and y stands for the number of columns to occupy
    @for $weight from 1 through $max-grid-columns - $startPos + 1 {
        .ct-start-#{$startPos}-#{$weight} {
            grid-column: #{$startPos} / span #{$weight};
        }
    } 
}

@for $startPos from 1 through $xsm-max-grid-columns { //.ctr-start-x-y does what .ct-start-x-y does but in responsive.
    @for $weight from 1 through $xsm-max-grid-columns - $startPos + 1 {
        @media screen and (max-width: #{$xsm}) {
            .ctr-start-#{$startPos}-#{$weight} {
                grid-column: #{$startPos} / span #{$weight};
            }
        }
    } 
}