@import '../../styles.scss';
@import '../styles/font-styles.scss';

button {
    background-color: var(--color-black);
    width: fit-content;
    color: var(--color-white);
    border-radius: var(--border-radius-size);
    padding: calc(var(--padding) * 3) calc(var(--padding) * 10);
    text-align:center;
    border: 0;
    @extend .primary_button;
    box-sizing: content-box;
    text-transform: uppercase;
    white-space: nowrap;
    
    &:hover {
        background-color: var(--color-light-lilac);
        @extend .cursor;
    }
    
    &:disabled{
       color: var(--disabled-white);
       background-color: var(--color-black);
       cursor: default;
    }
}

textarea,input{
    background-color: var(--color-grey-medium);
    color: var(--color-white);
    border-radius: var(--border-radius-size);
    border: 0;
    padding: calc(var(--padding) * 3) calc(var(--padding) * 10);
    outline: none;
    @extend .service_text_01; 

    &::placeholder {
        color: var(--color-white);
    }

    &:focus {
        outline: 2px solid var(--color-light-lilac);
    }
    &[type='checkbox'] {
        line-height: unset;
        outline: none;
    }  

}

textarea{
    min-height: calc(var(--base-layout)*41);
    resize: none;

}