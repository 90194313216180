@font-face {
    font-family: 'Lato-Regular';
    src: url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato-Light';
    src: url('../fonts/Lato/Lato-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald-Light';
    src: url('../fonts/Oswald/Oswald-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald-Bold';
    src: url('../fonts/Oswald/Oswald-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald-SemiBold';
    src: url('../fonts/Oswald/Oswald-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald-Medium';
    src: url('../fonts/Oswald/Oswald-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}