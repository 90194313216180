:root {
    --font-oswald-light:'Oswald-Light';
    --font-oswald-bold:'Oswald-Bold';
    --font-oswald-semibold:'Oswald-SemiBold';
    --font-oswald-medium:'Oswald-Medium';
    --font-lato-regular:'Lato-Regular';
    --font-lato-light:'Lato-Light';
    --font-lato-bold:'Lato-Bold';
}

.primary_button {
    font-family: var(--font-oswald-bold);
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
}

.menu_label_xl {
    font-family: var(--font-oswald-semibold);
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.05em;
}

.menu_label_s {
    font-family: var(--font-oswald-semibold);
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.05em;
}

.label_small {
    font-family: var(--font-oswald-semibold);
    font-size: 14px;
    line-height: 18px;
}

.display_xl {
    font-family: var(--font-oswald-medium);
    font-size: 50px;
    line-height: 50px;
}

.display_s {
    font-family: var(--font-oswald-medium);
    font-size: 42px;
    line-height: 42px;
}

.headline_01_xl_semibold {
    font-family: var(--font-oswald-semibold);
    font-size: 46px;
    line-height: 46px;
}

.headline_01_s_semibold_bright {
    font-family: var(--font-oswald-semibold);
    font-size: 40px;
    line-height: 40px;
}

.headline_01_xl_light {
    font-family: var(--font-oswald-light);
    font-size: 46px;
    line-height: 46px;
}

.headline_01_s_light {
    font-family: var(--font-oswald-light);
    font-size: 40px;
    line-height: 40px;
}

.headline_02_xl {
    font-family: var(--font-oswald-semibold);
    font-size: 30px;
    line-height: 32px;
}
 
.headline_02_xl_light {
    font-family: var(--font-oswald-light);
    font-size: 30px;
    line-height: 32px;
}

.headline_02_s {
    font-family: var(--font-oswald-semibold);
    font-size: 26px;
    line-height: 28px;
}

.headline_03 {
    font-family: var(--font-oswald-medium);
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.body_01_xl_light {
    font-family: var(--font-lato-light);
    font-size: 30px;
    line-height: 40px;
}

.body_01_s_light {
    font-family: var(--font-lato-light);
    font-size: 26px;
    line-height: 36px;
}

.body_02_xl_regular {
    font-family: var(--font-lato-regular);
    font-size: 20px;
    line-height: 32px;
}

.body_03_xl_regular {
    font-family: var(--font-lato-regular);
    font-size: 16px;
    line-height: 28px;
}

.body_02_s_bold {
    font-family: var(--font-lato-bold);
    font-size: 16px;
    line-height: 28px;
}

.body_02_s_bold_v2 {
    font-family: var(--font-lato-bold);
    font-size: 18px;
    line-height: 28px;
}

.caption_detail_xl {
    font-family: var(--font-lato-regular);
    font-size: 20px;
    line-height: 22px;
}

.caption_title_xl {
    font-family: var(--font-oswald-semibold);
    font-size: 22px;
    line-height: 24px;
}

.caption_title_s {
    font-family: var(--font-oswald-semibold);
    font-size: 20px;
    line-height: 22px;
}

.caption_detail_s {
    font-family: var(--font-lato-regular);
    font-size: 18px;
    line-height: 20px;
}

.service_text_01 {
    font-family: var(--font-lato-regular);
    font-size: 14px;
    line-height: 18px;
}

.news_font {
    font-family: var(--font-lato-regular);
    font-size: 20px;
    line-height: 28px;
}

.news_font_bold {
    font-family: var(--font-lato-bold);
    font-size: 20px;
    line-height: 28px;
}

//this font class is for debug purposes only
.error_font {
    color: red!important;
}